header {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 15;
  display: flex;
  align-items: center;
  height: max-content;
  padding-top: 8rem;
  border-bottom: 1rem solid rgba(#D9D9D9, .3);

  @media (max-width: $screen-lg) {
    padding-top: 4px;
    justify-content: space-between;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    height: 8rem;
    width: 100%;
    background: linear-gradient(98.68deg, #4D54CF 13.25%, #8C92F2 56.63%, #4D54CF 100%);

    @media (max-width: $screen-lg) {
      height: 4px;
    }
  }

  &.scroll-header {
    background-color: #00020F;

    > * {
      height: 80rem;

      @media (max-width: $screen-lg) {
        height: 80px;
      }
    }
  }

  &.active-header {

    .nav-wrap {
      transform: none;
      overflow-y: auto;
      opacity: 1;
    }
  }

  > * {
    height: 100rem;
    transition: $fast-transition;

    @media (max-width: $screen-lg) {
      height: 80px;
    }
  }

  .logo-wrap {
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 350rem;
    padding: 24rem 48rem;
    border-right: 1rem solid rgba(#D9D9D9, .3);

    @media (max-width: $screen-xxl) {
      min-width: 230px;
      padding: 24px 16px;
    }

    @media (max-width: $screen-lg) {
      min-width: max-content;
      padding: 16px 20px;
      border-right: 0;
    }

    img {
      width: 192rem;

      @media (max-width: $screen-xxl) {
        width: 160px;
      }
    }
  }

  .toggle-wrap {
    padding: 16px 20px;

    @media (max-width: $screen-lg) {
      position: relative;
      z-index: 3;
    }

    .btn-toggle {
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;

      .text {
        color: $white-color;
      }

      .icons {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .icon {
          min-width: 39px;
          max-width: 39px;
          height: 2px;
          background-color: $white-color;
        }
      }
    }
  }

  .nav-wrap {
    flex: 1;
    display: flex;
    align-items: center;
    transition: $med-transition;

    @media (max-width: $screen-lg) {
      flex-direction: column;
      position: fixed;
      align-items: flex-start;
      height: var(--app-height);
      z-index: 2;
      top: 0;
      left: 0;
      width: 100%;
      padding: 100px 20px 40px;
      transform: translateY(-150%);
      background-color: #00020F;
      gap: 17px;
      opacity: 0;
    }

    .nav-links-wrap {
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: $screen-lg) {
        height: max-content;
      }

      ul {
        height: 100%;
        display: flex;
        align-items: center;
        gap: 32rem;
        margin: 0;
        padding: 0;

        @media (max-width: $screen-lg) {
          align-items: flex-start;
          flex-direction: column;
          height: max-content;
        }

        li {
          display: flex;
          align-items: center;
          height: 100%;
          padding: 0;
          margin: 0;
          position: relative;

          &.active {
            background: linear-gradient(194.23deg, #ECEDFF -95.1%, #7279ED 40.86%, #4D54CF 227.18%), #FFFFFF;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;

            &::after {
              content: '';
              display: block;
              height: 4rem;
              width: 100%;
              position: absolute;
              bottom: -1rem;
              left: 0;
              background: linear-gradient(262.84deg, #ECEDFF -25.56%, #7279ED 49.31%, #4D54CF 151.91%), #D9D9D9;

              @media (max-width: $screen-lg) {
                display: none;
              }
            }
          }
        }

        a {
          color: $white-color;
          text-decoration: none;
          width: max-content;
          cursor: pointer;
          font-weight: 500;

          &:hover {
            color: $active-hover-color;
            transition: $fast-transition;
          }

          p {
            @media (max-width: $screen-lg) {
              font-size: 24px;
            }
          }
        }
      }
    }
  }

  .action-wrap {
    min-width: 350rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 24rem 48rem;
    border-left: 1rem solid rgba(#D9D9D9, .3);

    @media (max-width: $screen-xxl) {
      min-width: 230px;
      padding: 24px 16px;
    }

    @media (max-width: $screen-lg) {
      border-left: 0;
      padding: 0;
      height: max-content;
      min-width: max-content;
    }

    .btn-wrap {

    }
  }
}
