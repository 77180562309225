@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

@font-face {
  font-family: 'Stolzl';
  src: url('../../fonts/Stolzl-Regular.ttf') format('truetype'),
  url('../../fonts/Stolzl-Regular.otf') format('opentype');
  font-weight: 400;
}

$text-font: 'Inter', sans-serif;
$headline-font: 'Stolzl', sans-serif;

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

h1, h2 {
  font: 400 80rem/1.05 $headline-font;
  letter-spacing: calc(-80rem * .05);

  @media (max-width: $screen-lg) {
    font-size: 56px;
    letter-spacing: calc(-56px * .05);
  }

  @media (max-width: $screen-sm) {
    font-size: 44px;
    letter-spacing: calc(-48px * .05);
  }
}

h3 {
  font: 400 56rem/1 $headline-font;
  letter-spacing: calc(-56rem * .05);

  @media (max-width: $screen-lg) {
    font-size: 32px;
    letter-spacing: calc(-32px * .05);
  }
}

h4 {
  font: 400 32rem/1.2 $headline-font;
  letter-spacing: calc(-32rem * .05);

  @media (max-width: $screen-lg) {
    font-size: 20px;
    letter-spacing: calc(-20px * .05);
  }
}

h5 {
  font: 400 20rem/1.2 $text-font;
  letter-spacing: calc(-20rem * .02);

  @media (max-width: $screen-lg) {
    font-size: 16px;
    letter-spacing: calc(-16px * .02);
  }

  @media (max-width: $screen-sm) {
    font-size: 14px;
    letter-spacing: calc(-14px * .02);
  }
}

p {
  font: 400 16rem/1.2 $text-font;
  letter-spacing: calc(-20rem * .02);

  @media (max-width: $screen-lg) {
    font-size: 14px;
    letter-spacing: calc(-14px * .02);
  }
}
