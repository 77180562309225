.why-section {
  position: relative;
  padding: 0 80rem;
  margin: -170rem auto 96rem;

  @media (max-width: $screen-lg) {
    margin: -220px auto 96px;
  }

  @media (max-width: $screen-sm) {
    padding: 0 20px;
  }

  .title-wrap {
    display: flex;
    justify-content: center;
    margin-bottom: 48rem;
  }

  .items-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 20rem;

    @media (max-width: $screen-lg) {
      flex-direction: column;
    }

    .item {
      flex: 1;
      padding: 24rem;
      border-radius: 24rem;
      background: $card-gradient;

      @media (max-width: $screen-lg) {
        width: 100%;
      }

      .icon-wrap {
        margin-bottom: 115rem;

        @media (max-width: $screen-lg) {
          margin-bottom: 48px;
        }

        img {
          width: 48rem;

          @media (max-width: $screen-lg) {
            width: 36px;
          }
        }
      }

      .text-wrap {

        h4 {
          margin-bottom: 16rem;
        }

        p {
          color: rgba($text-color, .8);
        }
      }
    }
  }
}
