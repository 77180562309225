footer {
  padding: 48rem 80rem;
  border-top: 1rem solid rgba(#D9D9D9, .2);

  @media (max-width: $screen-sm) {
    padding: 32px 20px;
  }

  .content-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $screen-lg) {
      flex-direction: column-reverse;
      gap: 20px;
    }

    .copyright-wrap p {
      color: $white-color;
    }

    .links-wrap {

      ul {
        display: flex;
        gap: 24rem;
        margin: 0;
        padding: 0;

        li a {
          color: $white-color;
          text-decoration: none;

          &:hover {
            color: $active-hover-color;
          }
        }
      }
    }
  }
}
