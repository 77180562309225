@import "./header";
@import "./footer";

:root {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

* {
  font-family: $text-font;
  margin: 0;
}

*, *::before, *::after {
  box-sizing: border-box;
}

html {
  font-size: 1px;

  @media (min-width: $screen-xxl) {
    font-size: calc(100vw / 1440);
  }
}

body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  background-color: #00020F;

  &.overflow-hidden {
    overflow: hidden !important;

    @media (max-width: $screen-lg) {
      overflow-y: auto !important;
    }
  }
}

.desktop-only {

  @media (max-width: $screen-lg) {
    display: none !important;
  }
}

.mobile-only {
  display: none !important;

  @media (max-width: $screen-lg) {
    display: flex !important;
  }
}

.animation {
  transition: opacity .5s ease-out, transform .5s ease-out;
  height: max-content;

  &:not(.animation-active) {
    opacity: 0;
    transform: translateY(10vh);
  }
}
