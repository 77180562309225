.services-section {
  position: relative;
  padding: 0 80rem;
  margin: 0 auto 72rem;

  @media (max-width: $screen-sm) {
    padding: 0 20px;
  }

  .item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20rem;
    width: 100%;
    border-radius: 24rem;

    @media (max-width: $screen-lg) {
      flex-direction: column;
      align-items: flex-start;
      gap: 32px;
    }

    &.item-revert {

      @media (max-width: $screen-lg) {
        flex-direction: column-reverse;
      }

      .text-wrap {
        padding-left: 32rem;

        @media (max-width: $screen-lg) {
          padding-left: 0;
        }
      }
    }

    .text-wrap {
      flex: 1;

      .icon-wrap {
        margin-bottom: 24rem;

        img {
          width: 32rem;
        }
      }

      h3 {
        padding-bottom: 16rem;
      }

      h5 {
        color: $text-color;
        max-width: 540rem;
        line-height: 1.6;

        @media (max-width: $screen-lg) {
          max-width: 100%;
        }
      }

      .btn-wrap {
        margin-top: 32rem;

        @media (max-width: $screen-lg) {
          margin-top: 24px;
        }
      }
    }

    .img-wrap {
      flex: 1;
      width: 100%;
      border-radius: 24rem;

      img {
        width: 100%;
      }
    }
  }
}
