.ready-section {
  position: relative;

  .bg-wrap {
    width: 100%;

    img {
      width: 100%;
      min-height: 810rem;
      object-fit: cover;
      object-position: top;

      @media (max-width: $screen-lg) {
        min-height: 600px;
      }
    }
  }

  .text-wrap {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: absolute;
    max-width: max-content;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    padding: 0 20rem;
    height: 100%;

    @media (max-width: $screen-lg) {
      top: 0;
      padding: 0 20px;
    }

    h2 {
      padding-bottom: 24rem;
    }

    h5 {
      color: $text-color;
      max-width: 780rem;
    }

    .btn-wrap {
      position: absolute;
      bottom: 50rem;
      left: 0;
      right: 0;
      margin: 0 auto;

      @media (max-width: $screen-lg) {
        bottom: 40px;
      }
    }
  }
}
