.info-headline-section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 180rem 20rem 88rem;
  border-bottom: 1rem solid rgba(#D9D9D9, .3);

  @media (max-width: $screen-lg) {
    padding: 140px 20px 60px;
  }
}

.info-content-section {
  padding: 0 349rem;

  @media (max-width: $screen-xxl) {
    padding: 0 229px;
  }

  @media (max-width: $screen-lg) {
    padding: 0 20px;
  }

  .content-wrap {
    padding: 40rem 52rem 88rem;
    border-left: 1rem solid rgba(#D9D9D9, .3);
    border-right: 1rem solid rgba(#D9D9D9, .3);

    @media (max-width: $screen-lg) {
      padding: 32px 20px 48px;
    }

    .text-wrap {
      display: flex;
      flex-direction: column;
      gap: 48rem;
      color: $text-color;
      margin-bottom: 56rem;

      @media (max-width: $screen-lg) {
        margin-bottom: 32px;
        gap: 24px;
      }

      .item {
        display: flex;
        flex-direction: column;
        gap: 16rem;

        @media (max-width: $screen-lg) {
          gap: 12px;
        }

        a {
          color: $active-hover-color;
          text-decoration: none;
        }
      }
    }

    .btn-wrap {
      display: flex;
      justify-content: center;
    }
  }
}
