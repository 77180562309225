.btn-main {
  font: 600 18rem/1.2 $text-font;
  letter-spacing: calc(-18rem * .05);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  padding: 20rem 40rem;
  position: relative;
  max-width: max-content;
  border-radius: 90rem;
  background: rgba($white-color, .8);
  transition: $fast-transition;

  &:hover:not(.btn-transparent) {

    .bg {
      background: linear-gradient(153.03deg, #ECEDFF -130.93%, #7279ED -6.09%, #4D54CF 165%);
    }

    .text {
      color: $white-color;
    }
  }

  &.btn-small {
    font-size: 14rem;
    letter-spacing: calc(-14rem * .05);
    padding: 16rem 64rem;

    @media (max-width: $screen-xl) {
      padding: 16px 40px;
    }
  }

  &.btn-large {
    font-size: 24rem;
    line-height: 1;
    letter-spacing: calc(-24rem * .05);
  }

  &.btn-transparent {
    background: $btn-gradient;

    &:hover {

      .text {
        max-width: max-content;
        background: $text-gradient;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }

    .bg {
      background: #060A15;
    }

    .text {
      color: $white-color;
    }
  }

  .bg {
    z-index: 1;
    position: absolute;
    top: 2rem;
    left: 2rem;
    width: calc(100% - 4rem);
    height: calc(100% - 4rem);
    border-radius: 90rem;
    background: $btn-gradient;
  }

  .text {
    z-index: 2;
    position: relative;
    color: $black-color;
  }
}
