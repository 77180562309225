$black-color: #000000;
$white-color: #FFFFFF;
$text-color: #DDDDDD;
$active-hover-color: #7279ED;

$headline-gradient: linear-gradient(104.78deg, #ECEDFF 30.84%, #7279ED 72.01%, #4D54CF 128.42%);
$text-gradient: linear-gradient(117.33deg, #ECEDFF -16.12%, #7279ED 67.63%, #4D54CF 182.41%);
$btn-gradient: linear-gradient(157.27deg, #ECEDFF -1.44%, #7279ED 72.06%, #4D54CF 172.78%);
$card-gradient: linear-gradient(343.64deg, #0A041E 11.35%, #160E33 121.81%);

.headline-gradient {
  max-width: max-content;
  background: $headline-gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.text-gradient {
  max-width: max-content;
  background: $text-gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
