.hero-section {
  position: relative;

  .bg-wrap {
    width: 100%;

    img {
      width: 100%;
      min-height: 1080rem;
      object-fit: cover;
      object-position: top;

      @media (max-width: $screen-lg) {
        min-height: 960px;
      }
    }
  }

  .text-wrap {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: absolute;
    max-width: max-content;
    top: 172rem;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    padding: 0 20rem;

    @media (max-width: $screen-lg) {
      top: 140px;
      padding: 0 20px;
    }

    h1 {
      padding-bottom: 24rem;
    }

    h5 {
      color: $text-color;
      max-width: 780rem;
    }
  }
}
